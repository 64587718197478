import React, { Component } from 'react';
import { Row, Col, Typography, Layout, Space, message, List, Card, Radio, Tooltip, Button, Badge,ColorPicker, Tag, Modal } from 'antd'
import axios from 'axios';
import dayjs from 'dayjs';
import moment from 'moment';
import { BsBox2 } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { FaCashRegister, FaCreditCard } from "react-icons/fa";
import { UnorderedListOutlined, MenuOutlined, CheckCircleOutlined, CloseCircleOutlined, ShoppingCartOutlined, QuestionCircleOutlined } from '@ant-design/icons';


//componentes
import User from '../../../Hooks/Logged'
import useSearch from '../../../Hooks/useSearch';
import { IconTruck } from '../../Widgets/Iconos';
import SquareLogo from '../../Widgets/Icons/Square';
import { CardStatistics } from '../../Widgets/Cards';
import usePermissions from '../../../Hooks/usePermissions';
import PageHeader from '../../Widgets/PageHeader/PageHeader';
import FloatingButton from '../../Widgets/Floating Button/FloatingButton';
import { ButtonDelete, ButtonEdit, ButtonView } from '../../Widgets/Buttons';
import { getResponseError, paymentStatus, renderEstatusVenta, renderSource, tiposServiciosVenta, RenderSlot, tagDiasCreacion } from '../../Utils';

//Modales
import ModalVentas from "../Ventas/ModalVentas";
import ModalTerminal from '../Ventas/ModalTerminal';
import ModalVentasDetalles from "../Ventas/ModalVentasDetalles"
import DrawerProductosVenta from '../Ventas/DrawerProductosVenta';

const { Content } = Layout;
const { Text } = Typography


/**
 *
 *
 * @export
 * @class VentasList
 * @extends {Component}
 * @description Vista donde se listan todas las ventas
 */
class VentasList extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            ventas: {
                data: [],
                page: 1,
                limit: 20,
                filters: [],
                total: 0,
            },
            ventas_pendientes: {
            	count: 0,
            	total: 0
            },
        }
    }

    drawerFiltros = React.createRef()

    componentDidMount() {
        this.props.setShowSearch(true)
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token')
        this.getVentas({ search: this.props.search });
        this.getInfo()
    }

    componentDidUpdate(prevProps) {
        if (prevProps.search !== this.props.search) {
            this.getVentas({ search: this.props.search })
        }
    }


    /**
   * @memberOf Clientes
   * @method getClientes
   * @description Obtiene ls lista de Clientes
   */
    getVentas = ({
        page = this.state.ventas.page,
        limit = this.state.ventas.limit,
        search = this.state.ventas.search
    } = this.state.ventas) => {

        this.setState({loading: true})
        axios.get('/ventas', {
            params: {
                page: page,
                limit: limit,
                search: this.props.search,
                status: [6, 1, 2, 3, 4, 5, 7, 8],
                payment_status: 1,
            }
        })
            .then(({ data }) => {
                console.log('ventas=>', data)
                this.setState({ ventas: data })
            })
            .catch(error => {
                console.log(error)
                message.error(error?.response?.data?.message ?? "Error when obtaining the information")
            })
            .finally(() => this.setState({ loading: false }))
    }

    /**
    * @memberof Analytics
    * @method getInfo
    * @description Obtiene la informacion de las ventas pendientes
    */
    getInfo = () => {
        this.setState({ loading_info: true })
        axios.get('/analytics-dashboard',{
            params:{
                ventas_pendientes: true
            }
        })
        .then(({data}) => {
        	console.log("data", data);
        	this.setState({...data})

        }).catch(error => {
            console.log("error", error);

        }).finally(()=>this.setState({loading_info: false}))
    }

    /**
    * @memberOf Clientes
    * @method onChange
    * @description Cambia de vista de clientes de listados a agrupados
    */
    onChange = ({ target: { value } }) => {
        this.props.navigate('/admin/sales')
    };

    render() {

    	const { user } = this.props;
        const { ventas, loading } = this.state
        return (
            <>
                <PageHeader
                	backIcon={true}
                    className="admin-page-header"
                    title={<>Pending Payment Sales <Tooltip placement="topRight" title={"Sales with pending payment status (excluding those with 'Pending' status)"}> <QuestionCircleOutlined style={{fontSize: "14px", color: "black"}}/> </Tooltip></>}
                />
                <Content className="admin-content content-bg  pd-1">
                    <Row gutter={[24, 24]}>
                    	<Col xs={8}>
                    		<CardStatistics
                            	title={<>Pending Sales</>}
                                monto={<>{this.state?.ventas_pendientes?.count.toMoney(true, false) ?? 0} <br/> <small>$ {this.state?.ventas_pendientes.total?.toMoney(true, false) ?? 0}</small> </>} 
                            	icon={(color) => <FaCreditCard style={{color: "white"}} />} 
                            />
                    	</Col>
                        <Col xs={24} className="">
                            <List
                                loading={this.state.loading}
                                className="component-list"
                                itemLayout="horizontal"
                                locale={{ emptyText: "Sales not found" }}
                                dataSource={ventas.data}
                                pagination={{
                                    current: ventas.page,
                                    pageSize: ventas.limit,
                                    total: ventas.total,
                                    pageSizeOptions: [10, 20, 50, 100, 500, 1000],
                                    position: 'bottom',
                                    className: "flex-left ml-1",
                                    showSizeChanger: true,
                                    onChange: (page, limit) => {
                                        this.getVentas({ page, limit })
                                    }
                                }}
                                header={
                                    <Row align="middle">

                                        <Col flex="auto">
                                            <Row className="width-100 " align="middle" gutter={[0, 12]}>
                                                <Col xs={3} className="center" style={{ color: "gray" }} >
                                                    <Text ellipsis strong>Client / Folio</Text>
                                                </Col>
                                                <Col xs={2} className="center" >
                                                    <Text ellipsis strong>Items</Text>
                                                </Col>
                                                <Col xs={3} className="center" >
                                                    <Text ellipsis strong>Source</Text>
                                                </Col>
                                                <Col xs={1} className="center" >
                                                    <Text ellipsis strong>Color Tag</Text>
                                                </Col>
                                                <Col xs={1} className="center" >
                                                   
                                                </Col>
                                                <Col xs={3} className="center" >
                                                    <Text ellipsis strong>Status / Payment Status</Text>
                                                </Col>
                                                <Col xs={1} className="center" >
                                                    
                                                </Col>
                                                <Col xs={2} className="center" >
                                                    <Text ellipsis strong>Total</Text>
                                                </Col>
                                                <Col xs={4} className="center" >
                                                    <Text ellipsis strong>Date</Text>
                                                </Col>
                                                <Col xs={3} className="center" >
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                }

                                renderItem={venta => (
                                    <List.Item className="component-list-item">
                                        <Card className="card-list">
                                            <Row align="middle">
                                                <Col flex="auto">
                                                    <Row align="middle">

                                                            <Col flex="auto">
                                                                <Row className="width-100 " gutter={[0, 6]}>
                                                                    <Col xs={3} className="flex-left-column" >
                                                                        <Text strong> {venta.cliente_id?.nombre ?? "-"} {venta.cliente_id?.apellidos}</Text>
                                                                        <small> {venta.folio ?? "-"} {venta.servicio_express ? <Tag color="red">EXPRESS</Tag> : null}</small>
                                                                    </Col>
                                                                    <Col xs={2} className="center" >
                                                                        <Text ellipsis >{
                                                                        (venta?.detalles?.length)?
                                                                            venta?.detalles?.reduce( function (acc,detalle){  return (detalle?.servicio_id[0]?.tipo == 0)? acc + detalle.cantidad : acc ; }, 0)
                                                                            :0
                                                                        } pairs </Text>
                                                                    </Col>
                                                                    <Col xs={3} className="center" >
                                                                        <Text ellipsis >{renderSource(venta.from_terminal)}</Text>
                                                                    </Col>
                                                                    <Col xs={1} className="flex-column" >
                                                                        <ColorPicker value={venta.detalles[0]?.primary_color ?? '#FFF'} disabled={true} />
                                                                    </Col>
                                                                    <Col xs={1} className="flex-column" >
                                                                        {tiposServiciosVenta(venta.servicios)}
                                                                    </Col>
                                                                    <Col xs={3} className="flex-column" >
                                                                    	{renderEstatusVenta(venta.status)}
                                                                        {paymentStatus(venta.payment_status==2)}
                                                                    </Col>
                                                                    <Col xs={1} className="flex-column" >
                                                                        { venta.pick_and_deliver_flag ? <Tooltip title={"Marked as Pick-Up & Delivery"}>
                                                                            <IconTruck /> 
                                                                        </Tooltip>: null }
                                                                    </Col>
                                                                    <Col xs={2} className="center" >
                                                                        { (user?.rol_id?.tipo == 1)? <Text ellipsis >$ {venta.total?.toMoney(true) ?? 0}</Text>  : null }
                                                                    </Col>
                                                                    <Col xs={1} className="center" >
                                                                        {tagDiasCreacion(venta.createdAt, venta.status)}
                                                                    </Col>
                                                                    <Col xs={3} className="flex-column" >
                                                                        <Text ellipsis >{dayjs(venta.createdAt).format("MM/DD/YYYY HH:mm")}</Text>
                                                                        { venta.collection_date ? <> <small> Collection Date: {dayjs(venta?.collection_date).format('MM/DD/YYYY')} </small>
                                                                        <small> {RenderSlot(venta?.time_slot)} </small> </>: null}
                                                                    </Col>
                                                                    <Col xs={4} className="center" >
                                                                        <Space wrap>
			                                                                <Tooltip title={`This sale is ${venta.square_id ? "" : "not"} registered on square`}>
			                                                                    <Badge count={venta.square_id ? <CheckCircleOutlined color='#31A078' /> : <CloseCircleOutlined color='red' />}>
			                                                                        <Button type='primary'
			                                                                            disabled={true}
			                                                                            icon={<SquareLogo size="20px" />}
			                                                                            size="small"
			                                                                        />
			                                                                    </Badge>
			                                                                </Tooltip>
			                                                                <Tooltip title={`Checkout on terminal`}>
			                                                                    <Button type='primary'
			                                                                        disabled={venta.square_payment == true}
			                                                                        icon={<FaCashRegister />}
			                                                                        size="small"
			                                                                        onClick={() => this.setState({ modal_terminal_visible: true, venta_id: venta._id })}
			                                                                    />

			                                                                </Tooltip>

			                                                                <Tooltip placement="topRight" title={'Add or edit ventas'}>
			                                                                    <Button
			                                                                        size="small"
			                                                                        disabled={venta.square_payment == true}
			                                                                        type='primary'
			                                                                        icon={<BsBox2 />}
			                                                                        onClick={() => this.setState({ modal_visible_drawer: true, venta_id: venta._id })}
			                                                                    />
			                                                                </Tooltip>

			                                                                <ButtonView
			                                                                    size="small"
			                                                                    onClick={() => this.setState({ modal_visible: true, venta_id: venta._id })}
			                                                                />
			                                                                <ButtonEdit
			                                                                    size="small"
			                                                                    disabled={!this.props.canEdit}
			                                                                    onClick={() => this.setState({ modal_visible_venta: true, venta_id: venta._id })} />
			                                                                <ButtonDelete
			                                                                    size="small"
			                                                                    disabled={!this.props.canDelete || venta.square_payment == true}
			                                                                    onConfirm={() => {
			                                                                        console.log("is paid", venta)
			                                                                        this.setState({ loading: true })
			                                                                        axios.delete('/ventas', { params: { venta_id: venta._id } })
			                                                                            .then((response) => {
			                                                                                message.success("It was deleted correctly")
			                                                                                this.getVentas()
			                                                                            })
			                                                                            .catch((error) => {
			                                                                                message.error(getResponseError(error.response, "Delete error"));
			                                                                                this.getVentas()
			                                                                            })
			                                                                    }} />

			                                                            </Space>
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                </Col>
                                            </Row>
                                        </Card>
                                    </List.Item>
                                )}
                            />
                        </Col>
                    </Row>
                </Content>
                {/* </Spin> */}

                {this.props.canCreate && <FloatingButton title="New Sale" disabled={!this.props.canCreate} onClick={() => this.setState({ modal_visible_venta: true, venta_id: undefined })} />}

                <ModalVentasDetalles
                    visible={this.state.modal_visible}
                    onClose={() => {
                        this.setState({ modal_visible: false, venta_id: undefined })
                    }}
                    venta_id={this.state.venta_id}
                />

                <ModalVentas
                    visible={this.state.modal_visible_venta}
                    venta_id={this.state.venta_id}
                    onClose={(flag) => {
                        this.setState({ modal_visible_venta: false, venta_id: undefined })
                        this.getVentas()
                        this.getInfo()
                    }}
                />

                <ModalTerminal
                    visible={this.state.modal_terminal_visible}
                    venta_id={this.state.venta_id}
                    onClose={(flag) => {
                        this.setState({ modal_terminal_visible: false, venta_id: undefined })
                        this.getVentas()
                        this.getInfo()
                    }}
                />

                <DrawerProductosVenta
                    visible={this.state.modal_visible_drawer}
                    venta_id={this.state.venta_id}
                    onClose={(flag) => {
                        this.setState({ modal_visible_drawer: false, venta_id: undefined })
                        this.getVentas()
                        this.getInfo()
                    }}
                />
            </>
        )
    }
}



export default function (props) {
    const { setShow } = useSearch()

    const user = React.useContext(User)
    const permisos = usePermissions(user?.rol_id?.permisos, {
        canCreate: ["ventas", "create"],
        canEdit: ["ventas", "edit"],
        canDelete: ["ventas", "delete"],
    })

    const navigate = useNavigate();


    return <VentasList {...props}
        {...permisos}
    	user={user}
        setShowSearch={setShow}
        navigate={navigate}
    />
}