import React from 'react'
import { Route, Routes } from "react-router-dom";

import Analytics from '../../Components/Admin/Analytics/Analytics';
import VentasList from '../../Components/Admin/Analytics/VentasList';

/**
 * 
 * @export
 * @function RouterAnalytics
 * @description Router for Analytics routes 
 */
function RouterAnalytics(props) {
  return (
    <Routes>
      <Route path="" element={<Analytics {...props} />} />
      <Route path="/pending-sales" element={<VentasList {...props} />} />
    </Routes>
  )
}

export default RouterAnalytics